import { useState } from "react";
import TrackVisibility from "react-on-screen";
import projImg1 from "../assets/img/project-img1.jpg";
import projImg2 from "../assets/img/project-img2.jpg";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.jpg"; 
import projImg5 from "../assets/img/project-img5.jpg";
import projImg6 from "../assets/img/project-img6.jpg";

export const Projects = () => {
  const [activeTab, setActiveTab] = useState("operational");

  const projects = [
    {
      title: "Web Browser Calculator",
      description:
        "My first self-taught project. A Web browser calculator that can perform basic arithmetic operations, made using HTML, CSS, and JavaScript.",
      imgUrl: projImg3,
      url: "https://aimanmadan.github.io/WebBrowserCalculator/",
    },
    {
      title: "Single Click Upload",
      description:
        "Utilizing Selenium WebDriver to interact with the BeatStars website, handling tasks such as logging in, uploading .wav files, filling in basic track information, and setting artwork.",
      imgUrl: projImg1,
      url: "https://www.youtube.com/watch?v=yqroCOb7b0k&ab_channel=AimanMadan",
    },
    {
      title: "CSUSM Gen Ai Research",
      description:
        "As a Research Assistant at CSUSM, I developed the frontend using React and the backend with Express.js. While the backend is currently down, a live demo of the frontend is available for exploration.",
      imgUrl: projImg2,
      url: "https://aimanmadan.github.io/RichardsFrontEnd/",
    },
    {
      title: "Music Recommendation System",
      description:
        "A content-based music recommendation system using Spotify data from the 2000s to 2010s. Features include PCA for dimensionality reduction and cosine similarity for track recommendations.",
      imgUrl: projImg4,
      url: "https://youtu.be/liAlNHDmvu8", 
    },
    {
      title: "AI Club Website",
      description:
        "The official website for the CSUSM AI Club, showcasing events, projects, and resources to foster collaboration and learning in AI.",
      imgUrl: projImg5,
      url: "https://www.csusm-ai.club/",
    },
    {
      title: "ML Binary Classification of Glioma Grades",
      description:
        "Developed a machine learning model to classify glioma grades into High-Grade Gliomas (HGG) and Low-Grade Gliomas (LGG) using MRI-derived radiomic features. Achieved an F1-score of 91% using Gradient Boosting.",
      imgUrl: projImg6,
      url: "https://github.com/AimanMadan/CS471_FinalProject",
    },
  ];

  return (
    <section
      id="projects"
      className="relative bg-gray-900 dark:bg-gradient-to-r dark:from-blue-500 dark:to-blue-900 text-gray-300 py-16 overflow-hidden"
    >
      {/* Floating Bubbles */}
      <div className="absolute inset-0 z-0">
        {Array(10)
          .fill(0)
          .map((_, i) => (
            <div
              key={i}
              className="bubble"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 5}s`,
                animationDuration: `${10 + Math.random() * 10}s`,
              }}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          ))}
      </div>

      <div className="container mx-auto px-6 lg:px-16 relative z-10">
        <TrackVisibility>
          {({ isVisible }) => (
            <div
              className={`${
                isVisible ? "animate__animated animate__fadeIn" : ""
              }`}
            >
              <h2 className="text-4xl font-bold text-center mb-6">Projects</h2>
              <p className="text-center text-gray-300 max-w-2xl mx-auto">
                Explore my work showcasing expertise in web development,
                automation, and user-centric applications.
              </p>

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
                {projects.map((project, index) => (
                  <div
                    key={index}
                    className="glass relative overflow-hidden rounded-lg shadow-lg"
                    data-text={project.title}
                  >
                    <a
                      href={project.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block"
                    >
                      <img
                        src={project.imgUrl}
                        alt={project.title}
                        className="w-full h-48 object-cover"
                      />
                    </a>
                    <div className="p-4 text-center">
                      <h4 className="text-lg font-bold mb-2">{project.title}</h4>
                      <p className="text-sm">{project.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </TrackVisibility>
      </div>

      {/* CSS for Bubbles and Glass Design */}
      <style>
        {`
          /* Bubbles */
          .bubble {
            position: absolute;
            width: 150px;
            height: 150px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 50%;
            animation: bubble-move linear infinite;
            box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.2);
          }

          .bubble span {
            position: absolute;
            border-radius: 50%;
          }

          .bubble span:nth-child(1) {
            inset: 10px;
            border-left: 15px solid #0fb4ff;
            filter: blur(8px);
          }

          .bubble span:nth-child(2) {
            inset: 10px;
            border-right: 15px solid #ff4484;
            filter: blur(8px);
          }

          .bubble span:nth-child(3) {
            inset: 10px;
            border-top: 15px solid #ffeb3b;
            filter: blur(8px);
          }

          .bubble span:nth-child(4) {
            inset: 30px;
            border-left: 15px solid #ff4484;
            filter: blur(12px);
          }

          .bubble span:nth-child(5) {
            inset: 10px;
            border-bottom: 10px solid #fff;
            filter: blur(8px);
            transform: rotate(330deg);
          }

          @keyframes bubble-move {
            0% {
              transform: translateY(0) scale(1);
            }
            50% {
              transform: translateY(-200px) scale(1.1);
            }
            100% {
              transform: translateY(0) scale(1);
            }
          }

          /* Glass Card Design */
          .glass {
            position: relative;
            width: 100%;
            height: 100%;
            background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
            border: 1px solid rgba(255, 255, 255, 0.1);
            box-shadow: 0 25px 25px rgba(0, 0, 0, 0.2);
            border-radius: 15px;
            overflow: hidden;
            transition: transform 0.5s ease, box-shadow 0.5s ease;
          }

          .glass:hover {
            transform: translateY(-10px);
            box-shadow: 0 35px 35px rgba(0, 0, 0, 0.3);
          }

          .glass::before {
            content: attr(data-text);
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 40px;
            background: rgba(0, 0, 0, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 0.875rem;
          }
        `}
      </style>
    </section>
  );
};
