import { Container, Row, Col } from "react-bootstrap";
import { FaLinkedin, FaGithub, FaInstagram } from "react-icons/fa";

export const Footer = ({ darkMode }) => {
  return (
    <footer
      className={`footer relative bg-gradient-to-r ${
        darkMode
          ? "from-blue-500 to-blue-900 text-white"
          : "from-gray-100 to-gray-300 text-gray-900"
      } py-12`}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      <Container className="relative z-10">
        <Row className="align-items-center">
          {/* Logo Section */}
          <Col
            size={12}
            md={6}
            className="text-center text-md-start mb-8 mb-md-0"
          >
            <h1
              className={`text-4xl font-bold relative inline-block ${
                darkMode
                  ? "text-gray-300"
                  : "text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-blue-900"
              }`}
              style={{
                cursor: "pointer",
                marginBottom: "20px",
                marginTop: "40px",
                transition: "transform 0.5s ease, color 0.5s ease",
              }}
              onMouseEnter={(e) => {
                e.target.style.transform = "rotate(5deg) scale(1.1)";
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = "rotate(0deg) scale(1)";
              }}
            >
              Aiman
            </h1>
          </Col>

          {/* Social Icons */}
          <Col
            size={12}
            md={6}
            className="text-center text-md-end flex justify-center md:justify-end"
          >
            <div className="social-icons flex space-x-8 mt-6">
              <a
                href="https://www.linkedin.com/in/aimanmadan/"
                target="_blank"
                rel="noopener noreferrer"
                className="group"
              >
                <div
                  className={`icon-bg rounded-full p-2.5 transition-transform transform hover:scale-125 ${
                    darkMode
                      ? "bg-gray-800 shadow-gray-900"
                      : "bg-gray-100 shadow-gray-400"
                  }`}
                >
                  <FaLinkedin
                    size={30}
                    className={`text-blue-500 group-hover:text-blue-600 transition-colors ${
                      darkMode ? "text-white group-hover:text-gray-300" : ""
                    }`}
                  />
                </div>
              </a>
              <a
                href="https://github.com/AimanMadan"
                target="_blank"
                rel="noopener noreferrer"
                className="group"
              >
                <div
                  className={`icon-bg rounded-full p-2.5 transition-transform transform hover:scale-125 ${
                    darkMode
                      ? "bg-gray-800 shadow-gray-900"
                      : "bg-gray-100 shadow-gray-400"
                  }`}
                >
                  <FaGithub
                    size={30}
                    className={`text-gray-800 group-hover:text-black transition-colors ${
                      darkMode ? "text-white group-hover:text-gray-300" : ""
                    }`}
                  />
                </div>
              </a>
              <a
                href="https://www.instagram.com/aimangotthesauce/"
                target="_blank"
                rel="noopener noreferrer"
                className="group"
              >
                <div
                  className={`icon-bg rounded-full p-2.5 transition-transform transform hover:scale-125 ${
                    darkMode
                      ? "bg-gray-800 shadow-gray-900"
                      : "bg-gray-100 shadow-gray-400"
                  }`}
                >
                  <FaInstagram
                    size={30}
                    className={`text-pink-500 group-hover:text-pink-600 transition-colors ${
                      darkMode ? "text-white group-hover:text-gray-300" : ""
                    }`}
                  />
                </div>
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      {/* CSS for Social Icons */}
      <style>
        {`
          .social-icons .icon-bg {
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
            transition: box-shadow 0.3s ease, transform 0.3s ease;
          }
          .social-icons .icon-bg:hover {
            box-shadow: 0 12px 30px rgba(0, 0, 0, 0.5);
          }
        `}
      </style>
    </footer>
  );
};
