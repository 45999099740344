import { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import { FaLinkedin, FaGithub, FaInstagram } from "react-icons/fa";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const toggleDarkMode = () => setDarkMode(!darkMode);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
    // Close the mobile menu after navigating
    setMenuOpen(false);
  };

  return (
    <>
      <style>
        {`
          /* Nav Link Styling */
          .nav-link {
            text-decoration: none;
            position: relative;
            font-weight: 500;
            transition: all 0.3s ease;
            cursor: pointer; /* Change the cursor style */
          }

          .nav-link::after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: 50%;
            width: 0;
            height: 2px;
            background: ${darkMode ? "#4CAF50" : "#2196F3"};
            transition: all 0.3s ease;
            transform: translateX(-50%);
          }

          .nav-link:hover {
            color: ${darkMode ? "#4CAF50" : "#2196F3"};
            cursor: pointer; /* Ensure cursor looks interactive */
          }

          .nav-link:hover::after {
            width: 100%;
          }

          .nav-link.active {
            color: ${darkMode ? "#4CAF50" : "#2196F3"};
            font-weight: bold;
          }

          .nav-link.active::after {
            width: 100%;
          }

          /* Custom Cursor Effect */
          .nav-link:hover {
            cursor: url("https://cdn-icons-png.flaticon.com/512/1055/1055646.png"), pointer;
          }

          .toggle-switch {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 24px;
          }

          .toggle-switch .toggle-input {
            display: none;
          }

          .toggle-switch .toggle-label {
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 24px;
            background-color: ${darkMode ? "#4CAF50" : "#2196F3"};
            border-radius: 34px;
            cursor: pointer;
            transition: background-color 0.3s;
          }

          .toggle-switch .toggle-label::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            top: 2px;
            left: 2px;
            background-color: #fff;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
            transition: transform 0.3s;
            transform: ${darkMode ? "translateX(16px)" : "translateX(0)"};
          }
        `}
      </style>

      <nav
        className={`fixed top-0 w-full z-50 py-4 transition-all duration-300 ${
          scrolled ? "bg-gray-100 shadow-lg" : "bg-gray-200"
        } ${
          darkMode
            ? "dark:bg-gradient-to-r from-blue-500 to-blue-900 dark:text-gray-300"
            : "text-gray-900"
        }`}
      >
        <div className="container mx-auto flex items-center justify-between px-6">
          {/* Logo Text */}
          <h1
            className={`text-2xl font-bold ${
              darkMode
                ? "text-gray-300"
                : "text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-blue-900"
            }`}
            style={{
              transition: "color 0.3s ease, background 0.3s ease",
            }}
          >
            Aiman
          </h1>

          {/* Navigation Links (Hidden on Mobile, visible on md and above) */}
          <ul className="hidden md:flex space-x-8 font-medium">
            <li>
              <ScrollLink
                to="home"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}
                className={`nav-link ${activeLink === "home" ? "active" : ""}`}
                onSetActive={() => onUpdateActiveLink("home")}
                style={{
                  color: darkMode ? "white" : "black",
                }}
              >
                Home
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="skills"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}
                className={`nav-link ${activeLink === "skills" ? "active" : ""}`}
                onSetActive={() => onUpdateActiveLink("skills")}
                style={{
                  color: darkMode ? "white" : "black",
                }}
              >
                Skills
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="projects"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}
                className={`nav-link ${
                  activeLink === "projects" ? "active" : ""
                }`}
                onSetActive={() => onUpdateActiveLink("projects")}
                style={{
                  color: darkMode ? "white" : "black",
                }}
              >
                Projects
              </ScrollLink>
            </li>
          </ul>

          {/* Hamburger Button (Visible on mobile, hidden on md and above) */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="focus:outline-none"
            >
             
            </button>
          </div>

          {/* Social Media + Dark Mode Toggle (Always Visible) */}
          <div className="flex items-center space-x-4 ml-4">
            <a
              href="https://www.linkedin.com/in/aimanmadan/"
              target="_blank"
              rel="noopener noreferrer"
              className={`text-blue-500 hover:text-blue-600 ${
                darkMode ? "text-white hover:text-gray-300" : ""
              }`}
            >
              <FaLinkedin size={20} />
            </a>
            <a
              href="https://github.com/AimanMadan"
              target="_blank"
              rel="noopener noreferrer"
              className={`text-gray-800 hover:text-black ${
                darkMode ? "text-white hover:text-gray-300" : ""
              }`}
            >
              <FaGithub size={20} />
            </a>
            <a
              href="https://www.instagram.com/aimangotthesauce/"
              target="_blank"
              rel="noopener noreferrer"
              className={`text-pink-500 hover:text-pink-600 ${
                darkMode ? "text-white hover:text-gray-300" : ""
              }`}
            >
              <FaInstagram size={20} />
            </a>

            {/* Dark Mode Toggle Switch */}
            <div className="toggle-switch">
              <input
                className="toggle-input"
                id="toggle"
                type="checkbox"
                checked={darkMode}
                onChange={toggleDarkMode}
              />
              <label className="toggle-label" htmlFor="toggle"></label>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {menuOpen && (
          <ul
            className={`
              absolute
              right-6
              mt-2
              p-4
              rounded-md
              shadow-md
              flex
              flex-col
              space-y-4
              md:hidden
              z-[9999]      /* Ensures the menu sits on top of everything */
              ${darkMode ? "bg-gray-800" : "bg-white"}
            `}
            style={{ top: "60px" }} /* Adjust to match your navbar height */
          >
            <li>
              <ScrollLink
                to="home"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}
                className={`nav-link ${
                  activeLink === "home" ? "active" : ""
                }`}
                onSetActive={() => onUpdateActiveLink("home")}
                style={{
                  color: darkMode ? "white" : "black",
                }}
              >
                Home
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="skills"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}
                className={`nav-link ${
                  activeLink === "skills" ? "active" : ""
                }`}
                onSetActive={() => onUpdateActiveLink("skills")}
                style={{
                  color: darkMode ? "white" : "black",
                }}
              >
                Skills
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="projects"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}
                className={`nav-link ${
                  activeLink === "projects" ? "active" : ""
                }`}
                onSetActive={() => onUpdateActiveLink("projects")}
                style={{
                  color: darkMode ? "white" : "black",
                }}
              >
                Projects
              </ScrollLink>
            </li>
          </ul>
        )}
      </nav>
    </>
  );
};
