import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import python from "../assets/img/python.svg";
import github from "../assets/img/github.svg";
import raspberrypi from "../assets/img/raspberrypi.svg";
import selenium from "../assets/img/selenium.svg";
import figma from "../assets/img/figma.svg";
import arduino from "../assets/img/arduino.svg";
import flutter from "../assets/img/flutter.svg";
import react from "../assets/img/react.svg";
import tensorflow from "../assets/img/tensorflow.svg";
import cpp from "../assets/img/cpp.svg";
import android from "../assets/img/android.svg";
import scikitlearn from "../assets/img/scikit-learn.svg";
import tailwind from "../assets/img/tailwind.svg";

export const Skills = () => {
  const [expandedCategory, setExpandedCategory] = useState(null);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
    tablet: { breakpoint: { max: 1024, min: 768 }, items: 3 },
    mobile: { breakpoint: { max: 768, min: 0 }, items: 2 },
  };

  const highlightedSkills = [
    { img: python, label: "Python" },
    { img: tensorflow, label: "TensorFlow" },
    { img: scikitlearn, label: "Scikit-Learn" },
    { img: arduino, label: "Arduino" },
    
    
    { img: react, label: "React" },
    { img: tailwind, label: "Tailwind CSS" },
    { img: cpp, label: "C++" },
    
  ];

  const skillCategories = [
    {
      title: "Programming Languages",
      skills: [
        { img: cpp, label: "C++" },
        { img: python, label: "Python" },
      ],
    },
    {
      title: "Web Development",
      skills: [
        { img: react, label: "React JS" },
        { img: flutter, label: "Flutter" },
        { img: tailwind, label: "Tailwind CSS" },
       
      ],
    },
    {
      title: "AI",
      skills: [
        { img: scikitlearn, label: "Scikit-Learn" },
        { img: tensorflow, label: "TensorFlow" },
      ],
    },
    {
      title: "Tools & Platforms",
      skills: [
        { img: arduino, label: "Arduino" },
        { img: github, label: "GitHub" },
        { img: figma, label: "Figma" },
        { img: android, label: "Android" },
        { img: raspberrypi, label: "Raspberry Pi" },
        { img: selenium, label: "Selenium" },
        
      ],
    },
  ];

  const toggleCategory = (index) => {
    setExpandedCategory(expandedCategory === index ? null : index);
  };

  return (
    <section id="skills" className="bg-gray-900 py-12 shadow-lg">
      <div className="container mx-auto px-6 lg:px-16">
        {/* Section Header */}
        <div className="text-center mb-8">
         
            <div className="mb-12">
              <h1 className="text-2xl text-white font-semibold mb-4 text-center">
                Highlighted Skills
              </h1>
              <p className="text-gray-300 mt-4 text-center">
                
              </p>
            </div>

            <Carousel
              responsive={responsive}
              infinite
              autoPlay
              autoPlaySpeed={2000}
              arrows={false}
              containerClass="carousel-container"
              itemClass="px-4"
            >
              {highlightedSkills.map((skill, index) => (
                <div
                  key={index}
                  className="card flex flex-col items-center justify-center"
                >
                  <img src={skill.img} alt={skill.label} className="h-20 w-auto mb-4 z-10" />
                  <h5 className="text-lg font-medium text-white z-10">{skill.label}</h5>
                </div>
              ))}
            </Carousel>
            </div>

            {/* Interactive Skill Categories */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          {skillCategories.map((category, index) => (
            <div
              key={index}
              className="bg-gray-800 rounded-lg p-4 shadow-md cursor-pointer"
              onClick={() => toggleCategory(index)}
            >
              <h3 className="text-xl text-white font-semibold flex items-center justify-between">
                {category.title}
                <span className={`transition-transform duration-300 ${expandedCategory === index ? "rotate-180" : ""}`}>
                  ▼
                </span>
              </h3>
              <div
                className={`flex flex-wrap gap-4 mt-4 overflow-hidden transition-all duration-300 ${
                  expandedCategory === index ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
                }`}
              >
                {category.skills.map((skill, idx) => (
                  <div
                    key={idx}
                    className="flex flex-col items-center justify-center"
                  >
                    <img src={skill.img} alt={skill.label} className="h-12 w-auto" />
                    <span className="text-sm text-gray-300 mt-2">{skill.label}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Styles for Glass Cards */}
      <style>{`
        .card {
          width: 200px;
          height: 200px;
          background: #07182E;
          position: relative;
          display: flex;
          place-content: center;
          place-items: center;
          overflow: hidden;
          border-radius: 20px;
        }

        .card::before {
          content: '';
          position: absolute;
          width: 100px;
          background-image: linear-gradient(180deg, rgb(0, 183, 255), rgb(255, 48, 255));
          height: 130%;
          animation: rotBGimg 3s linear infinite;
          transition: all 0.2s linear;
        }

        @keyframes rotBGimg {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        .card::after {
          content: '';
          position: absolute;
          background: #07182E;
          inset: 5px;
          border-radius: 15px;
        }

        .card:hover::before {
          animation: rotBGimg 3.5s linear infinite;
        }
      `}</style>
    </section>
  );
};
