import { useState, useEffect } from "react";
import { ArrowRightCircle } from "react-bootstrap-icons";
import { Fade, Bounce, Slide } from "react-awesome-reveal";
import headerImg from "../assets/img/header-img.gif";

export const Banner = ({ darkMode }) => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const [scrollY, setScrollY] = useState(0);

  const toRotate = ["President of the AI Club", "CS Graduate May 2025"];
  const period = 1000;

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => clearInterval(ticker);
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(500);
    }
  };

  const handleResumeClick = () => {
    window.open(
      "https://drive.google.com/file/d/1xVuW2z8VGOcFfCeNu24uIDSCP5SfcsOo/view?usp=sharing",
      "_blank"
    );
  };

  // Calculate image transform based on scroll
  const imageOpacity = 1 - Math.min(scrollY / 300, 1);
  const imageTransform = `translateY(${Math.min(scrollY / 2, 150)}px)`;

  return (
    <section
      id="home"
      className="
        relative
        w-full
        min-h-screen
        flex
        items-center
        justify-center
        overflow-visible      /* Make sure we can see content on mobile */
        md:overflow-hidden    /* Hide overflow only on md and above */
       
        pt-24                /* Extra top padding for mobile */
        md:pt-[var(--navbar-height)]  /* 70px from md breakpoint upwards */
      "
      style={{ "--navbar-height": "70px" }}
    >
      {/* Video Background */}
      <video
        className="pointer-events-none absolute top-0 left-0 w-full h-full object-cover"
        src={require("../assets/vid/background.mp4")}
        autoPlay
        loop
        muted
        controls={false}
        disablePictureInPicture
        onContextMenu={(e) => e.preventDefault()}
      />
      {/* Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-black/35" />

      {/* Content */}
      <div className="z-10 flex flex-col md:flex-row items-center justify-center px-8 text-white flex-wrap">
        {/* Left Section */}
        <div className="flex-1 max-w-lg mb-6 md:mb-0">
          <Fade direction="down" triggerOnce>
            <span className="text-blue-600 font-semibold text-lg uppercase">
              Welcome to my Portfolio
            </span>
          </Fade>
          <Slide direction="left" triggerOnce>
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mt-4">
              My Name is{" "}
              <span
                className={`${
                  darkMode
                    ? "text-blue-600 hover:text-blue-900"
                    : "text-blue-800 hover:text-blue-900"
                } transition-transform duration-300 cursor-pointer`}
                onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
              >
                Aiman
              </span>
            </h1>
          </Slide>
          <Bounce triggerOnce>
            <h2 className="text-xl sm:text-2xl mt-2">
              <span className="text-blue-600 font-semibold">{text}</span>
            </h2>
          </Bounce>
          <Fade direction="up" triggerOnce>
            <p className="mt-6 leading-relaxed text-base sm:text-lg">
              I am the <b>President of the AI Club</b> at California State
              University San Marcos and will graduate with a degree in{" "}
              <b>Computer Science</b> in May 2025. My passion lies in{" "}
              <b>machine learning</b> and <b>artificial intelligence</b>. I
              specialize in developing innovative web scraping tools and
              Front-End.
            </p>
            <p className="mt-4 leading-relaxed text-base sm:text-lg">
              I aim to foster a thriving AI community at CSUSM, helping students
              explore the transformative potential of AI.
            </p>
            <p className="mt-4 leading-relaxed text-base sm:text-lg">
              Outside of tech, I channel my creativity into music production.
              You can explore my music journey and find more about my work by
              checking out my Instagram.
            </p>
            <button
              onClick={handleResumeClick}
              className="bg-blue-500 text-white px-6 py-2 rounded-lg mt-6 flex items-center transition-all hover:brightness-110"
            >
              Get Resume
              <ArrowRightCircle size={25} className="ml-2" />
            </button>
          </Fade>
        </div>

        {/* Right Image Section */}
        <div
          className="flex-1 flex justify-center"
          style={{
            opacity: imageOpacity,
            transform: imageTransform,
            transition: "opacity 0.3s ease, transform 0.3s ease",
          }}
        >
          <Slide direction="right" triggerOnce>
            <div className="w-48 h-48 sm:w-64 sm:h-64 md:w-80 md:h-80 rounded-full overflow-hidden shadow-lg">
              <img
                src={headerImg}
                alt="Header"
                className="w-full h-full object-cover"
              />
            </div>
          </Slide>
        </div>
      </div>
    </section>
  );
};
